* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: centuryGothic;
 
}

body {
  background-color: #fffef0;
  color: black;
  font-family: 'centuryGothic';
  z-index: 0;
}


@font-face {
  font-family: 'centuryGothic';
  src: local('Century Gothic'), url('./assets/font/Century\ Gothic.ttf') format('opentype');
}